h1{
  font-family: 'Raleway', Arial, Helvetica, sans-serif;
  color:#dd1f33;
}

h2{
  font-family: 'Raleway', Arial, Helvetica, sans-serif;
  color:#dd1f33;
}

ul{
  color:#333;
  text-align: left;
}

hr{
  color: #dd1f33;
  background-color: #dd1f33;
  border: none;
  height: 1px;
}

.account-select{
  margin-top: 40px;
  color:#333;
  font-size: 24px;
  text-align: center;
}

.account-select-path{
  font-family: 'Raleway', Arial, Helvetica, sans-serif;
  color:#333;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
}

.account-select-icon{
  margin-top: 10px;
  height: 81px;
  width: 66px;
}

.disclaimer{
  font-size: x-small;
}
.helper-text{
  font-size: 9pt;
  color:red;
  text-align: left;
  margin-top: 4px;
  font-weight: 300;
  padding-left: 12px;
}
.clickableIcon{
  cursor:default;
}
.clickableIcon:hover{
  cursor:pointer;
}

@media screen and (max-width: 1279px) {
  .hide-map{
    display:none
  }
}

.card-parent{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.card{
  padding:20px;
  padding-top:10px;
  border:1px solid #ccc;
  text-align: center;
  margin:10px;
}

.button{
  font-weight: 600;
  background-color: #dd1f33;
  border: none;
  color: #fff;
  padding: 11px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 18px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 20px;
}

.select-button{
  position: absolute;
  background-color: #dd1f33;
  border-radius: 5px;
  width: 100px;
  padding:5px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  bottom:15px;
  left:115px;
  cursor: pointer;
}

.account-select-card{
  position: relative;
  padding:20px;
  padding-top:10px;
  border:1px solid #ccc;
  text-align: center;
  max-width: 300px;
  min-width: 300px;
  margin:10px;
  height:300px;
}

.card-selected{
  background-color:#c0fcb3;
}

.card-left{
  margin-left:auto;
}

.card-container-left{
  text-align: left;
}

.card-right{
  margin-right:auto;
}

.card-container-right{
  text-align: left;
}

.card-full{
  margin-right:20%;
  margin-left:20%;
}

.store-list{
  height: 300px;
  overflow-y: scroll;
  border: 1px solid #ccc;
}

.store-list-long{
  height: 365px;
  overflow-y: scroll;
  border: 1px solid #ccc;
}

@media screen and (max-width: 475px) {
  .store-list-long{
    height: 70px;
  }
}

@media only screen and (max-width: 959px) {
  .card-left{
    margin-right:10%;
  }
  .card-container-left{
    text-align: center;
  }
  .card-right{
    margin-left:10%;
  }
  .card-container-right{
    text-align: center;
  }
  .card-full{
    margin-right:5%;
    margin-left:5%;
  }
}

.account-select-or{
  display:none
}

@media only screen and (max-width: 723px) {
  .account-select-or{
    width: 450px;
    display:block;
    text-align:center;
  }
}
@media only screen and (max-width: 700px) {
  .account-select-or{
    width: 350px;
  }
}

@media only screen and (max-width: 580px) {
  .account-select{
    font-size: 14px;
  }
}


@media only screen and (max-width: 400px) {

  h1{
    font-size: 22pt;
  }
  
  .card{
    padding:12px;
    padding-top: 0;
  }
  .store-list{
    height: 260px;
  }
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}